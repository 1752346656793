
import ireland_json from './ireland.json'
import nireland_json from './nireland.json'


import React, { useEffect, useRef } from "react";
import "./Map.css"
import { useContext } from '../Context.js';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';

const HEADER_PADDING = 66;
const NI_PADDING = 50;

const renderMap = (ref, selected, onCountyClick, language) => () => {
  const countyNamesHandler = (d) => {
    const [px, py] = projection(d3.geoCentroid(d))
    return `translate(${px}, ${py})`;
  }

  let {height, width} = ref.current.getBoundingClientRect();
  const projection = d3.geoNaturalEarth1().fitSize([width - NI_PADDING, height - HEADER_PADDING], ireland_json)

  let svg = d3.select(ref.current).selectAll('svg');
  svg.remove();
  svg = d3.select(ref.current).append('svg')
    .attr('height', `100%`)
    .attr('width', '100%')
    .attr("preserveAspectRatio", "xMidYMid meet")
    .attr("viewbox", `0 0 ${width - NI_PADDING} ${height - HEADER_PADDING}`)
    .classed("svg-content-responsive", true)

  // Creating path generator fromt the projecttion created above.
  const pathGenerator = d3.geoPath().digits(3)
    .projection(projection);

  // Creating the container
  const g = svg.append("g")
    .attr('class', 'center-container center-items us-state')

  // Creating state layer on top of counties layer.
  const countiesGroup = g.append("g")
    .attr("id", "сounties")
    .selectAll("path")
    .data(ireland_json.features)
    .enter();

  const counties = countiesGroup
    .append("path")
    .attr("key", feature => {
      return feature.properties.COUNTY
    })
    .attr("d", (data) => {
      return pathGenerator(data)
    })
    .attr("id", (data) => data.properties.COUNTY)
    .attr("class", "county")
    .classed('county-active', d => !d.properties.IS_NI && d.properties.COUNTY === selected?.properties?.COUNTY)
    .on('click', (event, d) => {
      onCountyClick(d);
    });

  const countyNames = countiesGroup
    .append('text')
      .classed('county-name', true)
      .attr('transform', countyNamesHandler)
      .html((d) => language === 'ua' ? d.properties.COUNTY_UA: d.properties.COUNTY)

  const northerIrelandGroup = g.append('g')
    .selectAll('path').data(nireland_json.features).enter();
  
  const northerIrelandCounties = northerIrelandGroup
    .append('path')
    .attr('d', pathGenerator)
    .attr("class", "county-ni")

  const resizeHandler = () => {
    let {height, width} = ref.current.getBoundingClientRect();
    svg.attr('viewbox', `0 0 ${width - NI_PADDING} ${height - HEADER_PADDING}`)
    projection.fitSize([width - NI_PADDING, height - HEADER_PADDING], ireland_json)
    pathGenerator.projection(projection);

    northerIrelandCounties.attr('d', pathGenerator);
    counties.attr('d', pathGenerator);
    countyNames.attr('transform', countyNamesHandler);
  }
  window.addEventListener('resize', resizeHandler);
  return () => {
    window.removeEventListener('resize', resizeHandler)
    d3.selectAll(ref.current).selectAll('svg').remove();
  }
}

export default function Map() {
  const ref = useRef(null);
  const {map: {onCountyClick, selected}} = useContext();
  const { i18n: {language} } = useTranslation();

  // eslint-disable-next-line
  useEffect(() => renderMap(ref, selected, onCountyClick, language)(), [selected, language]);

  return (
      <div className="viz" ref={ref} style={{"border":"1px", height: '100vh', paddingTop: '66px'}}></div>
  );
}