import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import {useContext} from '../Context.js'
import ConfirmDeleteModal from './ConfirmDeleteModal.js';
import React, {useState} from 'react';


export default function FacilitiesTable() {
    const {events, adminClient} = useContext();
    const {facilities, showFacilityEditor, setCurrentFacility, setFacilities,} = events;
    const [deleteModal, showDeleteModal] = useState(false);
    const [modalText, setModalText] = useState(null);
    const [deleteData, setDeleteData] = useState(null);
    const onEdit = (data) => () => {
        setCurrentFacility(data);
        showFacilityEditor(true);
    }
    const onRemove = (data) => async () => {
        showDeleteModal(true);
        setModalText(`Are you sure you want to delete facility ${data.facility_name}?`)
        setDeleteData(data)
    }
    const deleteCallback = async () => {
        await adminClient.removeFacility(deleteData);
        setFacilities(await adminClient.fetchFacilities());
        showDeleteModal(false);
    }
    return (
        <>
            <Table striped responsive hover>
                <thead>
                    <tr>
                        <th>Facility Name</th>
                        <th>Facility County</th>
                        <th>Facility Location</th>
                        <th>Facility Controls</th>
                    </tr>
                </thead>
                <tbody>
                    {facilities.map((d) => (
                        <tr key={d._id}>
                            <td>{d.facility_name}</td>
                            <td>{d.facility_county}</td>
                            <td>{d.facility_location}</td>
                            <td>
                                <Button onClick={onEdit(d)} variant='warning'>Edit</Button>
                                <Button onClick={onRemove(d)} variant='danger'>Remove</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <ConfirmDeleteModal show={deleteModal} text={modalText} 
                handleApprove={deleteCallback} handleClose={()=>showDeleteModal(false)}
            ></ConfirmDeleteModal>
        </>

    )
}