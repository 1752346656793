import { useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useContext } from './Context';
import {datetimeLocal} from './Admin/utils.js'
import Accordion from 'react-bootstrap/Accordion'
import { useTranslation } from 'react-i18next';

function EventPage({data}) {
  const { t } = useTranslation();

  return (
    <>
    <h1>{data.event_name}</h1>
    <p><b>{t('Location')}: </b> {data.event_location}</p>
    <p><b>{t('Time')}:</b> {datetimeLocal(data.event_time)}</p>
    <p> {data.event_description_en}</p>
    </>
  )
}

function FacilityPage({data}) {
  const { t } = useTranslation();

  return (
    <>
    <h1>{data.facility_name}</h1>
    <p><b>{t('Location')}: </b> {data.facility_location}</p>
    {data.facility_info_link ? <p><b>{t('Link')}: </b> <a href={data.facility_info_link}>{data.facility_info_link}</a></p>: null}
    {data.facility_email ? <p><b>Email: </b> <a href={'mailto:' + data.facility_email}>{data.facility_email}</a></p>: null}
    {data.facility_phone ? <p><b>{t("Phone")}: </b> {data.facility_phone} </p>: null}
    <p> {data.facility_description_en}</p>
    </>
  )
}


function OffCanvasExample({ ...props }) {
  const { t, i18n: { language} } = useTranslation();
  const {map: {handleClose, selected, showInfo,hideInfo}, events: {events, setEvents, facilities, setFacilities}, adminClient} = useContext() 
  const filteredEvents = events.filter((d) => d.event_county === selected?.properties.COUNTY)
  const filteredFacilities = facilities.filter((d) => d.facility_county === selected?.properties.COUNTY)

  useEffect(() => {
    adminClient.fetchEvents().then(setEvents)
    adminClient.fetchFacilities().then(setFacilities)

    return () => {};
  });

  return (
    <>
      <Offcanvas show={showInfo} onHide={hideInfo} onExited={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{language === 'ua' ? selected?.properties.COUNTY_UA : selected?.properties.COUNTY}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Accordion defaultActiveKey={0}>
            <Accordion.Item eventKey={0}>
              <Accordion.Header>
                {t("Facilities")}
              </Accordion.Header>
              <Accordion.Body>
                {filteredFacilities.map(d => <FacilityPage data={d}></FacilityPage>)}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey={1} >
              <Accordion.Header>
                {t('Events')}
              </Accordion.Header>
              <Accordion.Body>
                {filteredEvents.map(d => <EventPage data={d}></EventPage> )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function InfoPanel() {

  return (
    <>
      <OffCanvasExample key={'end'}  placement={'end'} name={'end'} />
    </>
  );
}
