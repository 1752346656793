import { useReducer } from "react";

const ACTIONS = {
    CURRENT_EVENT: 'CURRENT_EVENT',
    CURRENT_FACILITY: 'CURRENT_FACILITY',
    FETCH_EVENTS: 'FETCH_EVENTS',
    FETCH_FACILITIES: 'FETCH_FACILITIES',
    TOGGLE_EVENTS_EDITOR: 'TOGGLE_EVENTS_EDITOR',
    TOGGLE_FACILITY_EDITOR: 'TOOGLE_FACILITY_EDITOR',
    RESET_EVENT_EDITOR: 'RESET_EVENT_EDITOR',
    RESET_FACILITY_EDITOR: 'RESET_FACILITY_EDITOR',
};


function reducer(state, action) {
    switch(action.type) {
        case ACTIONS.FETCH_EVENTS:
            return {
                ...state,
                events: action.data,
            }
        case ACTIONS.FETCH_FACILITIES:
            return {
                ...state,
                facilities: action.data,
            }
        case ACTIONS.CURRENT_EVENT:
            return {
                ...state,
                current_event: action.data
            }
        case ACTIONS.CURRENT_FACILITY:
            return {
                ...state,
                current_facility: action.data
            }
        case ACTIONS.TOGGLE_EDITOR:
            return {
                ...state,
                show_editor: action.data
            }
        case ACTIONS.TOGGLE_FACILITY_EDITOR:
            return {
                ...state,
                show_facility_editor: action.data
            }
        case ACTIONS.RESET_EVENT_EDITOR:
            return {
                ...state,
                current_event: {...defaultState.current_event}
            }
        case ACTIONS.RESET_FACILITY_EDITOR:
            return {
                ...state,
                current_event: {...defaultState.current_event}
            }
        default:
            throw Error(`Unknown action ${action.type}`)
    }
}

const defaultState = {
    events: [],
    facilities: [],
    show_editor: false,
    show_facility_editor: false,
    current_event: {
        event_name: '',
        event_location: '',
        event_description_en: '',
        event_description_ua: '',
        event_time: (new Date()),
        event_county: 'DUBLIN'
    },
    current_facility: {
        facility_name: '',
        facility_location: '',
        facility_county: 'DUBLIN',
        facility_email: '',
        facility_phone: '',
        facility_description_en: '',
        facility_description_ua: '',
        facility_info_link: ''
    }
}

export default function EventsReducer() {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const {current_event, events, show_editor, facilities, 
        current_facility, show_facility_editor} = state;
    const setCurrentEvent = (data) => dispatch({
        type: ACTIONS.CURRENT_EVENT,
        data
    })
    const showEditor = (data) => dispatch({
        type: ACTIONS.TOGGLE_EDITOR,
        data
    })
    const setEvents = (data) => dispatch({
        type: ACTIONS.FETCH_EVENTS,
        data
    })
    const setCurrentFacility = (data) => dispatch({
        type: ACTIONS.CURRENT_FACILITY,
        data
    })
    const setFacilities = (data) => dispatch({
        type: ACTIONS.FETCH_FACILITIES,
        data
    })
    const showFacilityEditor = (data) => dispatch({
        type: ACTIONS.TOGGLE_FACILITY_EDITOR,
        data,
    })
    const resetFacilityEditor = (data) => dispatch({
        type: ACTIONS.RESET_FACILITY_EDITOR,
    })
    const resetEventEditor = (data) => dispatch({
        type: ACTIONS.RESET_EVENT_EDITOR
    })

    return {
        showEditor,
        showFacilityEditor,
        setCurrentEvent,
        setEvents,
        setFacilities,
        setCurrentFacility,
        resetFacilityEditor,
        resetEventEditor,
        current_event,
        show_editor,
        show_facility_editor,
        current_facility,
        events,
        facilities,
    }
}