import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import uaLocal from './locale/ua.json'
import enLocal from './locale/en.json'

const resources = {
  ua: {
    translation: uaLocal
  },
  en: {
    translation: enLocal
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: { 
      'ru': ['ua'],
      'default': ['en']
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;