import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {useContext} from '../Context.js'
import ireland from '../Map/ireland.json'
import {datetimeLocal} from './utils.js'
import Button from 'react-bootstrap/Button';


export default function EventEditor() {

	const {events: {current_event, show_editor, showEditor, setCurrentEvent, setEvents}, 
		   adminClient} = useContext();
	const handleClose = () => showEditor(false);

	const handleSave = async () => {
		await adminClient.addOrEditEvent(current_event);
		handleClose()
		const events = await adminClient.fetchEvents()
		setEvents(events);
	};

	const handleDatetimeChange = ({target: {value}}) => {
		if(!value) return;
		const ts = Date.parse(value);
		const date = new Date();
		date.setTime(ts);
		current_event.event_time = date;
		setCurrentEvent(current_event);
	}
	const handleChange = (field) => ({target: {value}}) => {
		const newState = {...current_event};
		newState[field] = value;
		setCurrentEvent(newState);
	} 

	return (
		<>

		<Modal
			show={show_editor}
			onHide={handleClose}
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header closeButton>
			<Modal.Title>Add/Modify Event</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>Event Name</Form.Label>
						<Form.Control type="textarea" rows={1} 
							placeholder="Event name" value={current_event.event_name} onChange={handleChange('event_name')} />
					</Form.Group>
					<Form.Group className="mb-3" >
						<Form.Label>Event description</Form.Label>
						<Form.Control as="textarea" rows={3} value={current_event.event_description_en} onChange={handleChange('event_description_en')} />
					</Form.Group>
					<Form.Group className="mb-3" >
						<Form.Label>Event description (in Ukrainian)</Form.Label>
						<Form.Control as="textarea" rows={3} value={current_event.event_description_ua}  onChange={handleChange('event_description_ua')}  />
					</Form.Group>
					<Form.Group className="mb-3" >
						<Form.Label>Event location</Form.Label>
						<Form.Control as="textarea" rows={1} value={current_event.event_location}  onChange={handleChange('event_location')} />
					</Form.Group>
					<Form.Group className="mb-3" >
						<Form.Label>Event County</Form.Label>
						<Form.Control
							as="select"
							value={current_event.event_county}
							onChange={handleChange('event_county')}
							>
								{
									ireland.features.map((d) => <option value={d.properties.COUNTY} key={d.properties.CC_ID}>{d.properties.COUNTY}</option>)
								}
						</Form.Control>
					</Form.Group>
					<Form.Group className="mb-3" >
						<Form.Label>Event time</Form.Label>
						<Form.Control as='input' type='datetime-local' value={datetimeLocal(current_event.event_time)} onChange={handleDatetimeChange}  />
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="primary" onClick={handleSave}>
				Save
			</Button>
			<Button variant="secondary">Close</Button>
			</Modal.Footer>
		</Modal>
		</>
	);
}
