import { GoogleAuthProvider } from "firebase/auth";
import { getAuth, getRedirectResult, signInWithRedirect } from "firebase/auth";

import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import firebaseConfigJson from './firebase_client.json'

export function initFirebaseApp() {
  const app = initializeApp(firebaseConfigJson);
  return app
}
export const app = initFirebaseApp();
export const database = getFirestore(app);
const provider = new GoogleAuthProvider();
const auth = getAuth();

export function redirectAuth() {
  signInWithRedirect(auth, provider);
}

export function firebaseAuth(onSuccess, onError) {
  getRedirectResult(auth)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;
      onSuccess(user, token)
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData?.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
      onError(errorCode, errorMessage, email, credential);
    });
}
