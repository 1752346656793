import React from "react";

export const actions = {
    SELECTED: 'selected',
    DESELECTED: 'deselected',
    HIDE_INFO: 'hide_info'
}


export function mapReducer(state, action) {
    switch (action.type) {
        case actions.SELECTED: {
            return {
                ...state,
                selected: action.data,
                showInfo: true,
            };
        }
        case actions.DESELECTED: {
            return {
                ...state,
                selected: null,
            };
        }
        case actions.HIDE_INFO: {
            return {
                ...state,
                showInfo: false,
            }
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

export const initialMapState = {
    selected: null,
    showInfo: false,
}

export default function MapReducer() {
    const [{selected, showInfo}, dispatch] = React.useReducer(mapReducer, initialMapState)
    
    const onCountyClick = (data) => dispatch({
        type: actions.SELECTED,
        data
      });
    
    const handleClose = () => dispatch({
        type: actions.DESELECTED,
    });
    const hideInfo = () => dispatch({type: actions.HIDE_INFO})
    
    return {
        selected,
        showInfo,
        onCountyClick,
        handleClose,
        hideInfo,
    }
}