import React from "react"
import {firebaseAuth, redirectAuth} from '../Firebase.js'
import {useContext} from '../Context.js'
import AdminPage from "./AdminPage.js";
import Button from 'react-bootstrap/Button';


export default function AdminPageWrapper() {
    const {
        auth: {user, handleAuth}, 
        events: {setEvents, setFacilities}, 
        adminClient
    } = useContext();
    const [message, setMessage] = React.useState('')
    const [loaded, setLoaded] = React.useState(false)
    React.useEffect(()=>{
        firebaseAuth(
            async (user, token) => {
                handleAuth({user, token});
                const events = await adminClient.fetchEvents()
                const facilities = await adminClient.fetchFacilities()
                setFacilities(facilities);
                setEvents(events);
                setLoaded(true);
            },
            (errorCode, errorMessage, email, credential) => { 
                setMessage(`You need to login with Google`)
                setLoaded(true);
            }
        )
    });

    return (
        <div>
            {loaded ? (
                user ? 
                <AdminPage></AdminPage> :
                <>
                    <Button onClick={redirectAuth}>Login</Button>
                    <p>{message}</p>
                </>
             ) : <p>Loading ...</p>

            }
        </div>
    )
}