import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {useContext} from '../Context.js'
import ireland from '../Map/ireland.json'
import Button from 'react-bootstrap/Button';


export default function FacilityEditor() {

	const {events: {current_facility, showFacilityEditor, setFacilities, show_facility_editor, setCurrentFacility}, 
		   adminClient} = useContext();
	const handleClose = () => showFacilityEditor(false);

	const handleSave = async () => {
		await adminClient.addOrEditFacilities(current_facility);
		handleClose()
		const events = await adminClient.fetchFacilities()
		setFacilities(events);
	};

	const handleChange = (field) => ({target: {value}}) => {
		const newState = {...current_facility};
		newState[field] = value;
		setCurrentFacility(newState);
	} 

	return (
		<>

		<Modal
			show={show_facility_editor}
			onHide={handleClose}
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header closeButton>
			<Modal.Title>Add/Modify Facility</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label>Facility Name</Form.Label>
						<Form.Control type="textarea" rows={1} 
							placeholder="Facility name" value={current_facility.facility_name} onChange={handleChange('facility_name')} />
					</Form.Group>
                    <Form.Group className="mb-3" >
						<Form.Label>Facility location</Form.Label>
						<Form.Control as="textarea" rows={1} value={current_facility.facility_location} onChange={handleChange('facility_location')} />
					</Form.Group>
                    <Form.Group className="mb-3" >
						<Form.Label>Facility County</Form.Label>
						<Form.Control
							as="select"
							value={current_facility.facility_county}
							onChange={handleChange('facility_county')}
							>
								{
									ireland.features.map((d) => <option value={d.properties.COUNTY} key={d.properties.COUNTY}>{d.properties.COUNTY}</option>)
								}
						</Form.Control>
					</Form.Group>
					<Form.Group className="mb-3" >
						<Form.Label>Facility description</Form.Label>
						<Form.Control as="textarea" rows={3} value={current_facility.facility_description_en} onChange={handleChange('facility_description_en')} />
					</Form.Group>
                    <Form.Group className="mb-3" >
						<Form.Label>Contact phone</Form.Label>
						<Form.Control as='input' type="tel"  value={current_facility.facility_phone} onChange={handleChange('facility_phone')} />
					</Form.Group>
                    <Form.Group className="mb-3" >
						<Form.Label>Email</Form.Label>
						<Form.Control as='input' type="email"  value={current_facility.facility_email} onChange={handleChange('facility_email')} />
					</Form.Group>
                    <Form.Group className="mb-3" >
						<Form.Label>More info</Form.Label>
						<Form.Control as="textarea"  value={current_facility.facility_info_link} onChange={handleChange('facility_info_link')} />
					</Form.Group>                
					<Form.Group className="mb-3" >
						<Form.Label>Facility description (in Ukrainian)</Form.Label>
						<Form.Control as="textarea" rows={3} value={current_facility.facility_description_ua}  onChange={handleChange('facility_description_ua')}  />
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
			<Button variant="primary" onClick={handleSave}>
				Save
			</Button>
			<Button variant="secondary">Close</Button>
			</Modal.Footer>
		</Modal>
		</>
	);
}
