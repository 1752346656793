import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import useMapReducer from './Reducers/MapReducer'
import useAuthReducer from './Reducers/AuthReducer'
import useEventsReducer from './Reducers/EventsReducer'
import './i18n'

import 'bootstrap/dist/css/bootstrap.min.css';
import Context from './Context';
import Admin from './Admin';
import AdminClient from './Admin/client'
import {database} from './Firebase'
import { HashRouter, Routes, Route } from "react-router-dom";


function Store({children}) {
  const value = {
    map: useMapReducer(),
    auth: useAuthReducer(),
    events: useEventsReducer(),
    adminClient: new AdminClient(database),
  }
  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <Store>
      <HashRouter>
        <Routes>
          <Route path='/' element={<App></App>} />
          <Route path='/admin' element={<Admin></Admin>} />
        </Routes>
      </HashRouter>
    </Store>
  </>
);
