import { useReducer } from "react";

export const actions = {
    LOGIN: 'login',
}

export function reducer(state, action) {
    switch(action.type) {
        case actions.LOGIN:
        return {
            ...state,
            ...action.data
        }
        default:
            return state;
    }
}
const DEFAULT_STATE = {user: null, token: ''}

export default function AuthReducer() {
  const [data, authDispatch] = useReducer(reducer, DEFAULT_STATE)
  
  const handleAuth = (data) => authDispatch({
    type: actions.LOGIN,
    data
  });

  return {
    handleAuth,
    ...data
  }
}