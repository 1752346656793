import { doc, collection, setDoc, addDoc, getDocs, deleteDoc } from "firebase/firestore"; 

export default class AdminClient {
    constructor(database) {
        this.database = database;
    }
    async addOrEditEvent(eventData) {
        const {_id} = eventData;
        if (_id) {
            delete eventData['_id'];
            return await setDoc(
                doc(this.database, 'events', _id), 
                eventData
            );
        } else {
            return await addDoc(
                collection(this.database, 'events'), 
                eventData
            );
        }
    }
    async addOrEditFacilities(eventData) {
        const {_id} = eventData;
        if (_id) {
            delete eventData['_id'];
            return await setDoc(
                doc(this.database, 'facilities', _id), 
                eventData
            );
        } else {
            return await addDoc(
                collection(this.database, 'facilities'), 
                eventData
            );
        }
    }

    async fetchEvents() {
        const data = await getDocs(collection(this.database, 'events'));
        const docs = [];
        data.forEach((d) => {
            const newData = d.data();
            newData._id = d.id;
            let date = new Date(); date.setTime(newData.event_time.seconds * 1000)
            newData.event_time = date;
            docs.push(newData)
        });
        return docs;
    }
    async fetchFacilities() {
        const data = await getDocs(collection(this.database, 'facilities'));
        const docs = [];
        data.forEach((d) => {
            const newData = d.data();
            newData._id = d.id;
            docs.push(newData)
        });
        return docs;
    }
    async removeFacility(facility) {
        await deleteDoc(doc(this.database, 'facilities', facility._id));
    }

    async removeEvent(event) {
        await deleteDoc(doc(this.database, 'events', event._id));
    }
}