import React from "react"
import {useContext} from '../Context.js'
import EventEditor from './EventEditor.js'
import Button from 'react-bootstrap/Button';
import EventTable from "./EventTable.js";
import Accordion from 'react-bootstrap/Accordion';
import FacilitiesTable from './FacilitiesTable.js'
import FacilityEditor from "./FacilityEditor.js";


export default function AdminPage() {
    const {
        auth: {user,}, 
        events: {showEditor, showFacilityEditor, resetEventEditor, resetFacilityEditor}, 
    } = useContext();


    const handleShow = () => {
        showEditor(true);
        resetEventEditor()
    }
    const handleFacilityEditorShow = () => {
        showFacilityEditor(true);
        resetFacilityEditor();
    }
    return (
        <div>
            <h1>User: { user?.displayName }</h1>

            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        Events
                    </Accordion.Header>
                    <Accordion.Body>
                        <Button variant="success" onClick={handleShow}>
                            Add event
                        </Button>
                        <EventTable></EventTable>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        Facilities
                    </Accordion.Header>
                    <Accordion.Body>
                        <Button variant="success" onClick={handleFacilityEditorShow}>
                            Add facility
                        </Button>
                        <FacilitiesTable></FacilitiesTable>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <EventEditor></EventEditor>
            <FacilityEditor></FacilityEditor>
        </div>
    )
}