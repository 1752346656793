import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import logo from './img/uact-logo.png';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ukraine_icon from './img/ukraine.png'
import ireland_icon from './img/ireland.png'


const LOCALE_FLAG_MAPPING = {
  en: ireland_icon,
  ua: ukraine_icon
}

function ToggleLanguage() {
  const { i18n: {changeLanguage, language} } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language)
  const newLanguage = currentLanguage === "ua" ? "en" : "ua";

  const handleChangeLanguage = () => {
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
  }

  return (
    <>
      <div onClick={handleChangeLanguage}>{currentLanguage.toUpperCase()}
        <img src={LOCALE_FLAG_MAPPING[currentLanguage]} alt={currentLanguage} 
            style={{width:'40px', height: '40px'}}></img>
      </div>
    </>
  )
}


function UACTNavbar() {
  return (
    <>
      <Navbar fixed={'top'} className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#">
            <img
              alt="uact-logo"
              src={logo}
              width="130"
              height="40"
              className="d-inline-block align-top"
            />{' '}
          </Navbar.Brand>
          <ToggleLanguage></ToggleLanguage>
        </Container>
      </Navbar>
    </>
  );
}

export default UACTNavbar;