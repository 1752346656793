import './App.css';
import Map from './Map/Map.js'
import InfoPanel from './InfoPanel.js'
import UACTNavbar from './Navbar.js';


function App() {
  return (
    <div className="App">
      <UACTNavbar />
      <Map></Map>
      <InfoPanel />
    </div>
  );
}

export default App;
