import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import {useContext} from '../Context.js'
import React, {useState} from 'react';
import {datetimeLocal} from './utils.js'
import ConfirmDeleteModal from './ConfirmDeleteModal.js'


export default function EventTable() {
    const {events: {events, showEditor, setCurrentEvent, setEvents}, 
           adminClient} = useContext();
    const [deleteModal, showDeleteModal] = useState(false);
    const [modalText, setModalText] = useState(null);
    const [deleteData, setDeleteData] = useState(null);
    const onEdit = (data) => () => {
        setCurrentEvent(data);
        showEditor(true);
    }
    const onRemove = (data) => async () => {
        showDeleteModal(true);
        setModalText(`Are you sure you want to delete event ${data.event_name}?`)
        setDeleteData(data)
    }
    const deleteCallback = async () => {
        await adminClient.removeEvent(deleteData);
        setEvents(await adminClient.fetchEvents());
        showDeleteModal(false);
    }
    return (
        <>
            <Table striped responsive hover>
                <thead>
                    <tr>
                        <th>Event Name</th>
                        <th>Event County</th>
                        <th>Event Time</th>
                        <th>Event Location</th>
                        <th>Event Controls</th>
                    </tr>
                </thead>
                <tbody>
                    {events.map((d) => (
                        <tr key={d._id}>
                            <td>{d.event_name}</td>
                            <td>{d.event_county}</td>
                            <td>{datetimeLocal(d.event_time)}</td>
                            <td>{d.event_location}</td>
                            <td>
                                <Button onClick={onEdit(d)} variant='warning'>Edit</Button>
                                <Button onClick={onRemove(d)} variant='danger'>Remove</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <ConfirmDeleteModal show={deleteModal} text={modalText} title={'Remove Event'}
                handleApprove={deleteCallback} handleClose={()=>showDeleteModal(false)}
            ></ConfirmDeleteModal>
        </>

    )
}